import React , { useState ,useEffect}from 'react';
import { find, map } from 'lodash';
import { connect } from 'react-redux';
import {
  Row, Col, Spinner, Number,
  LabeledInput, LabeledTextarea, LabeledProgress, Button, Label,
} from '@commonsku/styles';
import { createAddTagState } from '../../actions';
import { createUpdateClientAccount } from '../../actions/client';
import { createUpdateCompanyClientState } from '../../actions/company_search';
import { _createEventHandler, getIdentityUtils, findInOptions } from '../../utils';
import { getOptions } from './selectors';
import { LabeledSelect } from '../helpers';
import AvalaraEntityUseCodeSelect from '../avalara/AvalaraEntityUseCodeSelect';
import AutosuggestTagsInput from '../AutosuggestTagsInput';

export function ClientDetails({
  client={},
  filterOptions,
  updateClient,
  editDetails,
  parent_clients,
  autofocusField=false,
  addTag,
  ...props
}) {
  const { isAdmin, hasCapabilities } = getIdentityUtils(props.identity);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => {
    const salesTargetInput = document.getElementById('client_sales_target');
    if((autofocusField && autofocusField === 'sales_target') && editDetails && salesTargetInput) {
      salesTargetInput.focus();
      salesTargetInput.scrollIntoView();
    }
  }, []);

  useEffect(() => {
    setSelectedTags(client.client_tags || []);
  }, [client.client_tags]);

  if (!client) { return <Spinner />; }

  const onUpdate = (data) => updateClient(client.client_id, {
    ...client,
    ...data
  });
  const contacts = getOptions(client ? client.contacts : [], 'contact_id', function (v) {
    return v.contact_first_name + ' ' + v.contact_last_name;
  });

  const sales_target = client ? client.sales_target : 0;
  const y1_total = client && client.sales_report ? client.sales_report.y1_total : 0;

  const editLabelStyles = { marginBottom: 0, marginTop: 0, };
  const editInputStyles = { padding:0, marginTop: 5, marginBottom: 0, };

  return (
    <div>
      {props.access ?
        <Row style={{marginBottom: 15}}>
          <Col xs={editDetails ? 6 : 12}>
            <React.Fragment>
              <p style={{fontWeight: 'bold', marginTop: 0, marginBottom: 2}}>Sales to date</p>
              {(client.sales_target && !isNaN(client.sales_target) && parseInt(client.sales_target) !== 0)
              ? <LabeledProgress min={0} max={sales_target} value={y1_total} />
              : <div>
                  <span style={{ fontWeight: "bold", color: "#00a259" }}>
                    $<Number commas decimalPoints={0} num={y1_total} />
                  </span>
                </div>}
            </React.Fragment>
          </Col>
          {editDetails ?
            <Col xs={6} style={{textAlign: 'right', alignSelf: 'center',}}>
              <Button onClick={() => props.toggleEdit()}>Save</Button>
            </Col> : null}
        </Row>
      : null}

      <Row>
        <Col padded xl={6} sm={6} xs>
          {!editDetails || !props.perms ? <div>
            <p style={editLabelStyles}><strong>Client Name</strong></p>
            <p style={editInputStyles}>{client.client_name}</p></div>
          : <LabeledInput name="client_name" label="Client Name"
              value={client.client_name}
              disabled={!editDetails}
              onFocus={(e) => e.target.select()}
              onChange={_createEventHandler(onUpdate, 'client_name', {
                additionalPayload: { update_remote: false, }
              })}
              onBlur={_createEventHandler(onUpdate, 'client_name', {
                additionalPayload: { update_remote: false, }
              })} />}
        </Col>

        <Col padded xl={6} sm={6} xs>
          {!editDetails || !props.perms ? <div>
            <p style={editLabelStyles}><strong>Account Number</strong></p>
            <p style={editInputStyles}>{client.client_tenant_account_number}</p></div>
          : <LabeledInput name="account_number" label="Account Number"
            value={client.client_tenant_account_number}
            disabled={!editDetails}
            onFocus={(e) => e.target.select()}
            onChange={_createEventHandler(onUpdate, 'client_tenant_account_number', {
              additionalPayload: { update_remote: false, }
            })}
            onBlur={_createEventHandler(onUpdate, 'client_tenant_account_number', {
              additionalPayload: { update_remote: false, }
            })} />}
        </Col>
        <Col padded xl={6} sm={6} xs>
          {!editDetails || !props.perms ? <div>
            <p style={editLabelStyles}><strong>Margin Minimum</strong></p>
            <p style={editInputStyles}>{client.client_order_margin_minimum}</p></div>
          : <LabeledInput name="margin_minimum" label="Margin Minimum"
              value={client.client_order_margin_minimum}
              disabled={!editDetails}
              onFocus={(e) => e.target.select()}
              onChange={_createEventHandler(onUpdate, 'client_order_margin_minimum', {
                additionalPayload: { update_remote: false, }
              })}
              onBlur={_createEventHandler(onUpdate, 'client_order_margin_minimum', {
                additionalPayload: { update_remote: false, }
              })} />}
        </Col>
        <Col padded xl={6} sm={6} xs>
          {!editDetails || !props.perms ? <div>
            <p style={editLabelStyles}><strong>Sales Target</strong></p>
            <p style={editInputStyles}>
              $<Number commas decimalPoints={0} num={sales_target} />
            </p></div>
          : <LabeledInput name="sales_target" label="Sales Target" id="client_sales_target"
              value={sales_target}
              disabled={!editDetails}
              onFocus={(e) => e.target.select()}
              onChange={_createEventHandler(onUpdate, 'sales_target', {
                additionalPayload: { update_remote: false, }
              })}
              onBlur={_createEventHandler(onUpdate, 'sales_target', {
                additionalPayload: { update_remote: false, }
              })} />}
        </Col>
        <Col padded xl={6} sm={6} xs>
          {!editDetails || !props.perms ? <div>
            <p style={editLabelStyles}><strong>Primary Contact</strong></p>
            <p style={editInputStyles}>{findInOptions(contacts, client.primary_contact_id, {})['label']}</p></div>
          : <LabeledSelect name="primary_contact" label="Primary Contact"
              options={contacts}
              value={client.primary_contact_id}
              disabled={!editDetails}
              onChange={_createEventHandler(onUpdate, 'primary_contact_id', {
                additionalPayload: { update_remote: false, }
              })}
          />}
        </Col>
        <Col padded xl={6} sm={6} xs>
          {!editDetails || !props.perms ? <div>
            <p style={editLabelStyles}><strong>Industry</strong></p>
            <p style={editInputStyles}>{findInOptions(filterOptions.industries, client.industry_id, {})['label']}</p></div>
          : <LabeledSelect name="client_industry" label="Industry"
              options={filterOptions.industries}
              value={client.industry_id}
              disabled={!editDetails}
              onChange={_createEventHandler(onUpdate, 'industry_id', {
                type: 'select', additionalPayload: {update_remote: false,}
              })} />}
        </Col>
        <Col padded xl={6} sm={6} xs>
        {!editDetails || !props.perms ? <div>
          <p style={editLabelStyles}><strong>Tax</strong></p>
          <p style={editInputStyles}>{findInOptions(filterOptions.taxes, client.default_tax_id, {})['label']}</p></div>
        : <LabeledSelect name="client_tax" label="Tax"
            options={filterOptions.taxes}
            value={client.default_tax_id}
            disabled={!editDetails}
            onChange={_createEventHandler(onUpdate, 'default_tax_id', {
              type: 'select', additionalPayload: {update_remote: false,}
            })} />}
        </Col>
        <Col padded xl={6} sm={6} xs>
        {!editDetails || !props.perms ? <div>
          <p style={editLabelStyles}><strong>Default Terms</strong></p>
          <p style={editInputStyles}>{findInOptions(filterOptions.terms, client.default_terms_id, {})['label']}</p></div>
        : <LabeledSelect name="client_default_terms" label="Default Terms"
          options={filterOptions.terms}
          value={client.default_terms_id}
          disabled={!editDetails}
          onChange={_createEventHandler(onUpdate, 'default_terms_id', {
            type: 'select', additionalPayload: {update_remote: false,}
          })} />}
        </Col>
        <Col padded xl={6} sm={6} xs>
          {!editDetails || !props.perms ? <div>
            <p style={editLabelStyles}><strong>Currency</strong></p>
            <p style={editInputStyles}>{findInOptions(filterOptions.currencies, client.default_currency_id, {})['label'] ?? client.default_currency_id}</p></div>
          : <LabeledSelect name="client_default_currency" label="Currency"
            options={filterOptions.currencies}
            value={client.default_currency_id}
            disabled={!editDetails}
            onChange={_createEventHandler(onUpdate, 'default_currency_id', {
              type: 'select', additionalPayload: {update_remote: false,}
            })} />
          }
        </Col>
        <Col padded xl={6} sm={6} xs>
        {!editDetails || !props.perms ? <div>
          <p style={editLabelStyles}><strong>Client Rep</strong></p>
          <p style={editInputStyles}>{findInOptions(filterOptions.reps, client.sales_rep_id, {})['label']}</p></div>
        : <LabeledSelect name="client_rep" label="Client Rep"
            options={filterOptions.reps}
            value={client.sales_rep_id}
            disabled={(isAdmin() || hasCapabilities(['MODIFY-CLIENT-REP']))}
            onChange={_createEventHandler(onUpdate, 'sales_rep_id', {type: 'select', additionalPayload: {update_remote: false,}})} />}
        </Col>

        <Col padded xl={6} sm={6} xs>
          {!editDetails || !props.perms ? <div>
            <p style={editLabelStyles}><strong>Status</strong></p>
            <p style={editInputStyles}>{findInOptions(filterOptions.statuses, client.account_status_id, {})['label']}</p></div>
            : <LabeledSelect name="client_status" label="Status" options={filterOptions.statuses}
              value={client.account_status_id}
              disabled={!editDetails}
              onChange={_createEventHandler(onUpdate, 'account_status_id', {type: 'select', additionalPayload: {update_remote: false,}})} />}
          </Col>

        <Col padded xl={6} sm={6} xs>
          {!editDetails || !props.perms ? <div>
            <p style={editLabelStyles}><strong>Parent Client</strong></p>
            <p style={editInputStyles}>{findInOptions(parent_clients, client.parent_client_id, {})['label']}</p></div>
          : <LabeledSelect
              name="parent_client"
              label="Parent Client"
              cacheOptions
              disabled={!editDetails}
              value={client.parent_client_id}
              options={parent_clients}
              onChange={_createEventHandler(onUpdate, 'parent_client_id', {type: 'select', additionalPayload: {update_remote: false,}})}
            />}
        </Col>

        <Col padded xl={6} sm={6} xs>
          {!editDetails || !props.perms ? <div>
            <p style={editLabelStyles}><strong>Tags</strong></p>
            <p style={editInputStyles}>{
              client.client_tags && Array.isArray(client.client_tags) && client.client_tags.length>0
                ? client.client_tags.map((v, i) =>
                  i === client.client_tags.length -1 ? v : v + ', '
                ) : null}</p></div>
          : <div style={{marginBottom:'4.4em'}}>
            <Label>Tags</Label>
            <AutosuggestTagsInput
              value={map(selectedTags, (tag) => {
                return find(filterOptions.all_tags_labels, { label: tag }) || { label: tag };
              })}
              tags={filterOptions.all_tags_labels}
              onChange={(value)=>{
                const newTags = map(value, 'label');
                setSelectedTags(newTags);
                onUpdate({ update_remote: false, client_tags: newTags });
              }}
            />
          </div>}
        </Col>

        <Col padded xl={6} sm={6} xs>
          {!editDetails || !props.perms ? <div>
            <p style={editLabelStyles}><strong>Website</strong></p>
            {URL.canParse(client.client_website) ?
              (
                <a target="_blank" rel="noreferrer" href={client.client_website} style={editInputStyles}>
                  {client.client_website}
                </a>
              ) : (
                <p style={editInputStyles}>
                  {client.client_website}
                </p>
              )
            }
          </div>
          : <LabeledInput name="website" label="Website"
              value={client.client_website}
              disabled={!editDetails}
              onFocus={(e) => e.target.select()}
              onChange={_createEventHandler(onUpdate, 'client_website', {
                additionalPayload: { update_remote: false, }
              })}
              onBlur={_createEventHandler(onUpdate, 'client_website', {
                additionalPayload: { update_remote: false, }
              })} />}
        </Col>

        <Col padded xl={6} sm={6} xs>
        <p style={editLabelStyles}><strong>Quickbooks Online Integration</strong></p>
          {!editDetails || !props.perms ? <div>
            <p style={editInputStyles}>
              {client.qbo_customer_ref ? 'Mapped' : 'Not mapped'}
            </p>
          </div>
          : <div>
            <p style={editInputStyles}>
              {client.qbo_customer_ref ?
                <div>
                  Mapped - <a onClick={() => onUpdate({ qbo_customer_ref: false, update_remote: false })}>click to unmap</a>
                </div>
                : 'Not mapped'
              }
            </p>
          </div>
          }
        </Col>

        {!!(client.avalara_entity_use_code || (editDetails && props.perms)) && <Col padded xl={6} sm={6} xs>
          <p style={editLabelStyles}><strong>Entity Use Code</strong></p>
          {!editDetails || !props.perms
            ? <p style={editInputStyles}>{client.avalara_entity_use_code}</p>
            : <AvalaraEntityUseCodeSelect
              name="avalara_entity_use_code"
              disabled={!editDetails}
              value={client.avalara_entity_use_code}
              onChange={(avalara_entity_use_code) => {
                return onUpdate({ avalara_entity_use_code, update_remote: false });
              }}
            />
          }
        </Col>}

        <Col padded xl>
          {!editDetails || !props.perms ? <div>
            <p style={editLabelStyles}><strong>Profile</strong></p>
            <p style={editInputStyles}>
              {client.client_profile ? client.client_profile.split(/\r?\n/).map((v, i) => <span key={i}>{v}<br /></span>) : null}
            </p></div>
          : <LabeledTextarea name="client_profile" label="Profile"
              value={client.client_profile}
              disabled={!editDetails}
              onChange={_createEventHandler(onUpdate, 'client_profile', {
                additionalPayload: { update_remote: false, }
              })}
              onBlur={_createEventHandler(onUpdate, 'client_profile', {
                additionalPayload: { update_remote: false, }
              })} />}
          </Col>
      </Row>
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    identity: state.identity,
    user_id: state.identity.user_id,
    parent_clients: getOptions(Object.values((state.entities.company_search || {}).parent_clients || {}), 'client_id', 'client_name'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateClient: (client_id, data) => {
      dispatch(createUpdateClientAccount(client_id, data));
      dispatch(createUpdateCompanyClientState(client_id, {
        sales_target: data.sales_target,
        client_name: data.client_name,
        client_rep_user_id: data.sales_rep_id,
      }));
    },
    addTag: tag => dispatch(createAddTagState(tag)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);
